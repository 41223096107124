.mainContainer {
  width: 100%;
  text-align: center;
}

.formContainer {
  width: 100%;
  margin: 30px 30px;
}

.formPasswordContainer {
  width: 100%;
  margin: 0px 30px;
}

.formLoginContainer {
  width: 500px;
  height: 246px;
  border: 2px solid #CB3312;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.title {
  margin: 40px 30px;
}

.subTitle {
  margin: 0px 30px;
}

.tableTitle {
  width: 100%;
  margin: 0px 30px 10px 30px;
}

.tableTitle.mainLevel {
  margin-bottom: 20px;
}

.tableContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.textInfo {
  margin: 0px 30px 20px 10px;
}

.errorMessage {
  color: red;
}

.formErrorMessage {
  color: red;
  text-align: right;
  font-weight: 600;
  margin-top: 10px;
}

.formOkMessage {
  color: green;
  text-align: right;
  font-weight: 600;
  margin-top: 10px;
}

.loadingImgLogin {
  height: 36px;
  margin-right: 30px;
}

.loadingDivTable {
  width: 100%;
  text-align: center;
}

.loadingDivTable img {
  height: 180px;
}

.loadingDivForm {
  width: 100%;
  text-align: right;
  padding-right: 90px;
}

.loadingDivForm img {
  height: 30px;
}

.loginErrorMessage {
  color: red;
  font-weight: 600;
  margin-top: 10px;
  float: left;
}

.calendarErrorMessage {
  color: red;
  font-weight: 600;
  margin-bottom: 20px;
}

.form-control .react-datetime-picker__wrapper {
  border: none;
}

.col {
  padding-left: 30p;
  padding-right: 30px; 
}

p {
  color: green;
  font-size: 68px;
  font-weight: 600;
  text-align: center;
}

.input {
  width: 500px;
  margin: auto;
  border: 2px solid #000000;
  border-radius: 10px;
  padding: 5px;
  font-size: 26px;
}

.btn-login {
  color: #909090 !important;
  border-color: #909090 !important;
  background-color: #CB3312 !important;
}

.imgBuscar {
  height: 52px;
  margin-bottom: -16px;
  margin-left: 5px;
  cursor: pointer;
}

.imgBuscar.disabled {
  pointer-events: none;
  cursor: progress;
}

.vertical-list {
  float: left;
  background: #222D32;
  color: #b8c7ce;
  list-style: none;
  display: block !important;
  width: 50px;
}

.vertical-list.show {
  width: 220px;
}

.vertical-list.show li span{
  display: inline;
}

.collapse.width {
  height: auto;
  -webkit-transition: width 1s ease;
  -moz-transition: width 1s ease;
  -o-transition: width 1s ease;
  transition: width 1s ease;
}

.collapsing.width { 
  height: auto;
  -webkit-transition: width 1s ease;
  -moz-transition: width 1s ease;
  -o-transition: width 1s ease;
  transition: width 1s ease;
  
}

.vertical-list ul {
  padding-left: 0px;
}

.vertical-list li {
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
}

.vertical-list li span{
  display: none;
  margin-left: 15px;
}

.vertical-list a {
  color: #b8c7ce;
  text-decoration: none;
  padding: 12px 5px 12px 15px;
  display: block;
}

.vertical-list a:hover {
  background: #1E282C;
  color: #ffffff;
  text-decoration: none;
}

.vertical-list a.active {
  background: #1E282C;
  color: #ffffff;
  border-left: 3px solid #5ce1e6;
}

.header {
  min-height: 50px;
  background: #CB3312;
  color: #FFFFFF;
}

.header span {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 20px;
}

.header .homeLink {
  text-decoration: none;
  width: 220px;
  height: 50px;
  display: block;
  text-align: center;
  top: 10px;
  position: relative;
  float: left;
}

.loginHeader {
  min-height: 50px;
  background: #CB3312;
  color: #FFFFFF;
}

.loginHeader span {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 20px;
  position: relative;
  top: 8px;
  left: 25px;
}

.loginBody {
  margin: 25px;
}

.viewer {
  text-align: left;
  margin: 20px 100px;
}

.img-responsive {
  width: 30%;
  margin-top: 50px;
}

.homeText {
  font-size: 36px;
}

.navbar-button {
  width: 50px;
  height: 50px;
  float: left;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 220px;
  color: #FFFFFF;
}

.navbar-button:hover {
  background: #CB3312
}

.navbar-button svg {
  width: 50px;
  height: 50px;
  padding: 12px;
}

.logged-user-button {
  height: 50px;
  float: left;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  color: #FFFFFF;
  text-align: right;
}

.logged-user-button:hover {
  background: #CB3312
}

.logged-user-button svg {
  width: 46px;
  height: 50px;
  padding: 12px;
}

.logged-user-button span {
  font-size: 16px;
  margin-right: 15px;
}

.collapse-user-menu {
  position: absolute;
  top: 50px;
  right: 0px;
  color: #FFFFFF;
  text-align: left;
  background: #CB3312;
  width: 220px;
  font-weight: 600;
  z-index: 900;
}

.collapse-user-menu span{
  display: none;
}

.collapse-user-menu.collapsing {
  position: absolute;
  top: 50px;
  right: 0px;
}

.menuItem {
  display: block;
  color: #ffffff;
  text-decoration: none;
  width: 220px;
  height: 50px;
  padding: 12px 5px 12px 15px;
  cursor: pointer;
}

.menuItem:hover {
  background: #CB3312;
  color: #ffffff;
  text-decoration: none;
}

.dataTable {
  margin: 0px 30px 36px 30px !important;;
}

.dataTable tbody tr{
  cursor: pointer;
}

.calendarContainer {
  height: 600px;
  width: 95%;
  margin: 0px 30px 150px 30px;
}

.calendarContainer .rbc-toolbar-label {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.rbc-agenda-empty {
  visibility: hidden;
}

.rbc-agenda-empty::before {
  visibility: visible;
  content: 'No hay nada en la agenda para hoy.';
}

.eventDone {
  background-color: #c0c0c0 !important;
  color: #808080 !important;
}

.footer {
  text-align: right;
}

.footer .button {
  margin-left: 10px;
}

.linkAdd {
  position: absolute;
  right: 30px;
  top: 101px;
  color: #404040;
  cursor: pointer;
}

.collapse-password-form {
  width: 100%;
  margin-right: 60px;
}

.preview {
  width: 250px;
}


@media only screen and (max-width: 1200px) {

  .calendarContainer {
    width: 94%;
  }

}

@media only screen and (max-width: 992px) {

  .img-responsive {
    width: 40%;
  }

  .calendarContainer {
    width: 92%;
  }

} 

@media only screen and (max-width: 768px) {
 
  .header {
    position: fixed;
    width: 100%;
    z-index: 900;
  }

  .title {
    margin: 80px 30px 50px 30px;
  }
  
  .navbar-button {
    left: 0px;
    z-index: 1000;
    position: fixed;
  }

  .header .homeLink {
    left: 50%;
    margin-left: -100px;
  }

  .vertical-list {
    position: fixed;
    left: -220px;
    z-index: 1000;
    width: 220px;
    margin-top: 50px;
  }

  .vertical-list li span{
    display: inline;
  }

  .vertical-list.show {
    left: 0px;
  }
  
  .collapse.width {
    height: auto;
    position: fixed;
    -webkit-transition: left 1s ease;
    -moz-transition: left 1s ease;
    -o-transition: left 1s ease;
    transition: left 1s ease;
  }
  
  .collapsing.width { 
    height: auto;
    position: fixed;
    -webkit-transition: left 1s ease;
    -moz-transition: left 1s ease;
    -o-transition: left 1s ease;
    transition: left 1s ease;
  }

  .logged-user-button span {
    display: none;
  }

  .homeText {
    font-size: 30px;
  }

} 

@media only screen and (max-width: 575px) {

  .formLoginContainer {
    width: 90%;
    height: 380px;
    border: 2px solid #5ce1e6;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .footer {
    text-align: left;
  }

  .footer .button {
    width: 100%;
    margin-left: 0px;
    margin-top: 6px 
  }
  
  .loginErrorMessage {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .homeText {
    font-size: 26px;
  }

} 

@media only screen and (max-width: 420px) {

  .title {
    font-size: 26px;
  }

  .linkAdd {
    top: 88px;
  }

  .homeText {
    font-size: 16px;
  }

}